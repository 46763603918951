import { Button, Navbar } from '@blueprintjs/core'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { StorageFabric } from '@tools/storage'
import { useAppState } from '@application/hooks/contexts/app-state'
import { useAppHandlers } from '@application/hooks/contexts/app-handlers'
import { articlesLocation, vehicleListLocation, jetsLocation } from '@application/locations'
import techiiaUrl from '@assets/techiia.png'

import styles from './index.module.scss'

function Nav() {
  const appState = useAppState()
  const appHandlers = useAppHandlers()

  const onLogout = () => {
    const storage = StorageFabric.getLocalStorage()
    appHandlers.setUnauthenticatedState()
    storage.clear()
  }

  return (
    <Navbar fixedToTop className={styles.container}>
      <div className={classNames(styles.wrap, 'bp3-navbar-group bp3-align-left')}>
        <div className={styles.logo}>
          <img src={techiiaUrl} alt="techiia" />
        </div>
        <Link to={vehicleListLocation}>
          <Button className="bp3-minimal bp3-intent-primary" icon="drive-time" text="Car’s description" />
        </Link>
        <Link to={jetsLocation}>
          <Button className="bp3-minimal" icon="waves" text="Jet-skis" />
        </Link>
        <Link to={articlesLocation}>
          <Button className="bp3-minimal" icon="document" text="Articles" />
        </Link>
      </div>
      <div className={classNames(styles.wrap, 'bp3-navbar-group bp3-align-right')}>
        {appState.user && (<Button onClick={onLogout} className="bp3-minimal" rightIcon="log-out" text={appState.user.username} />)}
      </div>
    </Navbar>
  )
}

export default Nav
