import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Button } from '@blueprintjs/core'
import { Article } from '@domain/article'
import { formatDate } from '@tools/date'
import { getArticleLocation, getArticlePreviewLocation, createArticleLocation } from '@application/locations'

import Nav from '../_components_/nav'
import { ListItem } from '../_components_/list-item'
import { List } from '../_components_/list'
import { ArticlesStatus } from '../_components_/article-status'
import ArticlesFilter from './filter'
import { useArticlesList } from './hook'
import styles from './index.module.scss'

export const ArticlesPage = () => {
  const { state, handlers } = useArticlesList()
  return (
    <>
      <Nav />
      <div className={styles.container}>
        <List
          header={(
            <div className={styles.header}>
              <h1>Articles</h1>
              <ArticlesFilter searchTerm={state.searchTerm} handleSearchTermChange={handlers.onSearchTermChange} />
              <Link to={createArticleLocation}>
                <Button className={classNames(styles.headerBtn, 'bp3-intent-primary')} large>Create article</Button>
              </Link>
            </div>
          )}
          labels={(
            <div className={styles.label}>
              <div className={styles.labelWrap}>
                <span>Title</span>
                <span>Status</span>
                <Button className={styles.sorting} minimal small rightIcon="swap-vertical">Last changed</Button>
                <span className={styles.labelEdit}>Edit</span>
              </div>
            </div>
          )}
          renderItem={(article) => (
            <ListItem
              className={styles.item}
              key={article.id}
              to={article.status === Article.LISTED_STATUS ? getArticleLocation(article.id) : getArticlePreviewLocation(article.id)}
              preview={article.thumbnail}
              title={article.title}
            >
              <ArticlesStatus status={article.status} text={article.statusTxt} />
              <p className={styles.date}>{formatDate(article.lastChanged)}</p>
            </ListItem>
          )}
          items={state.articles}
          onPageChange={data => handlers.setCurrentArticlesPage(data.selected + 1)}
          pageCount={state.totalArticlesPage}
          forcePage={state.currentArticlesPage - 1}
          searchTerm={state.searchTerm}
        />
      </div>
    </>
  )
}
