import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppStateProvider } from '@application/contexts/app-state'

import { AppRoutes } from './routes'

const queryClient = new QueryClient()

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppStateProvider>
          <AppRoutes />
        </AppStateProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}
