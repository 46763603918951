export class Result {
  static ok(ok) {
    return new Ok(ok)
  }

  static err(err) {
    return new Err(err)
  }
}

export class Ok {
  constructor(value) {
    this.value = value
  }

  isOk() {
    return true
  }

  isErr() {
    return false
  }
}

export class Err {
  constructor(value) {
    this.value = value
  }

  isOk() {
    return false
  }

  isErr() {
    return true
  }
}
