import { HttpFabric } from '@tools/http'

const http = HttpFabric.getFetchHttp()

export const getAllVehicles = async () => {
  return http.get(`/api/vehicles`)
}

export const getVehicles = async (pagination, filters) => {
  let params = `offset=${pagination?.offset || 0}&limit=${pagination?.limit || 0}`
  if (filters?.brand) params += `&make=${filters.brand.toLowerCase()}`
  if (filters?.model) params += `&model=${filters.model.toLowerCase()}`
  if (filters?.year) params += `&year=${filters.year}`
  if (filters?.search) params += `&search=${filters.search}`
  return http.get(`/api/vehicles?${params}`)
}

export const getVehicle = async (id) => {
  return http.get(`/api/vehicles/${id}?editorjs=true`)
}

export const updateVehicle = async (id, data) => {
  return http.put(`/api/vehicles/${id}`, data)
}
