import { useFormik } from 'formik'
import { postLogin, getUserById } from '@repositories/user'
import { useAppHandlers } from '@application/hooks/contexts/app-handlers'
import { StorageFabric } from '@tools/storage'

const storage = StorageFabric.getLocalStorage()

export function useLoginPage() {
  const appHandlers = useAppHandlers()

  const form = useFormik({
    initialValues: {
      nickname: '',
      password: '',
    },
    validate: (values) => {
      const errors = {}

      if (!values.nickname) {
        errors.nickname = 'Nickname is required.'
      }

      if (!values.password) {
        errors.password = 'Password is required.'
      }

      return errors
    },
    onSubmit: async (values) => {
      const loginResult = await postLogin(values.nickname, values.password)
      if (loginResult.isOk()) {
        try {
          const body = await loginResult.value.json()
          storage.save('token', body['X-Token-Access'])
          const userResult = await getUserById()
          if (userResult.isOk()) {
            const user = await userResult.value.json()
            appHandlers.setAuthenticatedState(user)
            storage.save('user-id', user.id)
          }
        } catch {}
      }
    },
  })

  return {
    state: {
      values: form.values,
      isSubmitting: form.isSubmitting,
    },
    handlers: {
      handleSubmit: form.handleSubmit,
      handleChange: form.handleChange,
    },
  }
}
