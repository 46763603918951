import { articlesLocation } from '@application/locations'

import { ControlPanel } from '../../../_components_/control-panel'
import { useControlPanel } from './hook'

export const ArticleControlPanel = (props) => {
  const { article, isEditMode, clickSaveBtn, clickSavePreviewBtn, disabledSaving, isPreviewAvailable } = props
  const { handlers } = useControlPanel(article)

  return (
      <ControlPanel
          confirmBtnTxt="Delete article"
          deleteTxt={`Are you sure you want to delete article ${article?.title}?`}
          location={articlesLocation}
          isEditMode={isEditMode}
          isDisabledSaving={disabledSaving}
          isPreviewAvailable={isPreviewAvailable}
          onClickSaveBtn={clickSaveBtn}
          onClickSavePreviewBtn={clickSavePreviewBtn}
          onRemove={handlers.onRemoveArticle}
      />
  )
}
