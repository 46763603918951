import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import { vehicleListLocation } from '@application/locations'

import Nav from '../_components_/nav'
import VehicleOptions from './options'
import { useEditVehicleDesc } from './hook'
import styles from './index.module.scss'

export const VehiclePage = () => {
  const { state, handlers } = useEditVehicleDesc()

  return (
    <>
      <Nav />
      <div className={styles.container}>
        <div className={styles.carInfo}>
          <div className={styles.carTitle}>
            <h1>Edit description</h1>
            {state.vehicle && (
              <span>{state.vehicle.name}</span>
            )}
          </div>
          <VehicleOptions
            selectedFuelType={state.selectedFuelType}
            selectedSeatsNumber={state.selectedSeatsNumber}
            selectedDoorsNumber={state.selectedDoorsNumber}
            isPopular={state.popularCheckbox}
            videoLink={state.videoLink}
            onSelectFuelType={handlers.selectFuelType}
            onSelectSeatsNumber={handlers.selectSeatsNumber}
            onSelectDoorsNumber={handlers.selectDoorsNumber}
            onVideoUrlChange={handlers.setVideoLink}
            handleIsPopularChange={handlers.onIsPopularChange}
          />
          {state.vehicle && (
            <div className={styles.carWrap}>
              <img className={styles.carImg} src={state.photoPreview} alt="car" />
            </div>
          )}
        </div>
        <div className={styles.carContent} id="editor" />
      </div>
      <div className={styles.control}>
        <div className={styles.controlWrap}>
          <Link to={vehicleListLocation}><Button className="bp3-large">Cancel</Button></Link>
          <div>
            {/*<Button className="bp3-large bp3-outlined bp3-intent-primary">Preview</Button>*/}
            <Button className="bp3-intent-primary bp3-large" disabled={!state.isSavingAvailable} onClick={handlers.onSave}>
              Save changes
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
