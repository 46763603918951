import React from 'react'

import Nav from '../_components_/nav'
import { JetForm } from '../_features_/jet-form'
import { useEditJet } from './hook'

export const EditJetPage = () => {
  const { state, handlers } = useEditJet()
  return (
    <>
      <Nav />
      {state.isEditingAvailable && (
        <JetForm onSaveJet={handlers.onUpdateJet} jet={state.jet} />
      )}
    </>
  )
}
