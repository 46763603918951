import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Table from '@editorjs/table'
import { VehiclePayload } from '@domain/vehicle/payload'
import { updateVehicle } from '@repositories/vehicles'
import { useGetVehicle } from '@application/hooks/http/get-vehicle'
import { useEditor } from '@application/hooks/editor'
import { vehicleListLocation } from '@application/locations'

import { showFailNotification } from '../_components_/fail-notification'

const ERROR_MESSAGE = 'Something went wrong. Please try again later'

export function useEditVehicleDesc() {
  const navigate = useNavigate()
  const { id: vehicleId } = useParams()
  const { initEditor, getEditorData } = useEditor({
    tools: {
      table: Table,
    }
  })

  const [selectedFuelType, selectFuelType] = useState('')
  const [selectedSeatsNumber, selectSeatsNumber] = useState('')
  const [selectedDoorsNumber, selectDoorsNumber] = useState('')
  const [videoLink, setVideoLink] = useState('')
  const [popularCheckbox, setPopularCheckbox] = useState(false)

  const vehicleQuery = useGetVehicle(vehicleId, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (vehicle) => {
      initEditor(vehicle.descriptionJSON || vehicle.description)
      selectFuelType(vehicle?.fuelType ?? '')
      setVideoLink(vehicle.videoLink ?? '')
      selectSeatsNumber(vehicle.numberOfSeats ? `${vehicle.numberOfSeats} seats` : '')
      selectDoorsNumber(vehicle.numberOfDoors ? `${vehicle.numberOfDoors} doors` : '')
      setPopularCheckbox(vehicle?.isPopular ?? false)
    }
  })

  const onIsPopularChange = (event) => {
    setPopularCheckbox(event.target.checked)
  }

  const onSave = async () => {
    try {
      const editorData = await getEditorData()
      const result = await updateVehicle(vehicleId, new VehiclePayload({
        fuelType: selectedFuelType,
        seatsNumber: getNumberFromOption(selectedSeatsNumber),
        doorsNumber: getNumberFromOption(selectedDoorsNumber),
        videoLink,
        editorData,
        popularCheckbox
      }).DTO)
      if (result.isOk()) {
        navigate(vehicleListLocation)
      } else {
        showFailNotification(ERROR_MESSAGE)
      }
    } catch {
      showFailNotification(ERROR_MESSAGE)
    }
  }

  const getNumberFromOption = (option) => {
    return +option.split(' ')[0]
  }

  return {
    state: {
      isSavingAvailable: selectedFuelType && selectedSeatsNumber && selectedDoorsNumber && videoLink && vehicleQuery.data,
      photoPreview: vehicleQuery.data?.thumbnail,
      vehicle: vehicleQuery.data,
      selectedFuelType,
      selectedSeatsNumber,
      selectedDoorsNumber,
      videoLink,
      popularCheckbox
    },
    handlers: {
      selectFuelType,
      selectSeatsNumber,
      selectDoorsNumber,
      setVideoLink,
      onIsPopularChange,
      onSave
    }
  }
}
