import React from 'react'

import Pagination from '../../_components_/pagination'
import styles from './index.module.scss'

export const List = ({ items, onPageChange, pageCount, forcePage, searchTerm, header, labels, renderItem }) => {
  return (
    <div className={styles.container}>
      {header}
      <div className={styles.list}>
        {labels}
        {items.map(renderItem)}
        {items.length === 0 && searchTerm && (
          <div className={styles.empty}>
            <p className={styles.emptyTxt}>No search results for "{searchTerm}"</p>
          </div>
        )}
      </div>
      <Pagination
        onPageChange={onPageChange}
        pageCount={pageCount}
        forcePage={forcePage}
      />
    </div>
  )
}
