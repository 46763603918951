import { Button, HTMLSelect } from '@blueprintjs/core'

import InputSearch from '../../_components_/search-input'
import { useVehiclesFilter } from './hook'
import styles from './index.module.scss'

export default function VehiclesFilter({
 allVehicles,
 onSelectBrand,
 onSelectModel,
 onSelectYear,
 selectedBrand,
 selectedYear,
 searchTerm,
 handleSearchTermChange,
 selectedModel,
 handleClearAll
}) {
  const { state } = useVehiclesFilter(allVehicles, selectedBrand)
  return (
    <div className={styles.filter}>
      <InputSearch className={styles.search} value={searchTerm} onChange={handleSearchTermChange} />
      <HTMLSelect
        value={!selectedBrand ? 'Make' : selectedBrand}
        onChange={(event) => onSelectBrand(event.target.value === 'Make' ? '' : event.target.value)}
        options={state.brands}
      />
      <HTMLSelect
        value={!selectedModel ? 'Model' : selectedModel}
        onChange={(event) => onSelectModel(event.target.value === 'Model' ? '' : event.target.value)}
        options={state.models}
        disabled={!selectedBrand}
      />
      <HTMLSelect
        value={!selectedYear ? 'Year' : selectedYear}
        onChange={(event) => onSelectYear(event.target.value === 'Year' ? '' : event.target.value)}
        options={state.years}
        disabled={!selectedBrand}
      />
      {selectedBrand && (
        <Button className='bp3-minimal bp3-intent-danger' onClick={handleClearAll}>Clear all</Button>
      )}
    </div>
  )
}
