import { createPhoto } from '@repositories/photos'

const PHOTO_UPLOAD_BUCKET = 'thumbnails'

export function useUploadPhotos() {
  const uploadPhotos = async (files) => {
    return Promise.all(files.map(uploadPhoto))
  }

  const uploadPhoto = async (file) => {
    const result = await createPhoto(file, PHOTO_UPLOAD_BUCKET)
    if (result.isOk()) {
      const data = await result.value.json()
      return data.url[0]
    }
  }

  return {
    uploadPhoto,
    uploadPhotos
  }
}
