import { Entity } from '../_entity_'
import { Vehicle } from '../vehicle'

export class Article extends Entity {

  constructor(data) {
    super(data)
    this.vehiclesEntity = this.data.vehicles ? this.data.vehicles.map(item => new Vehicle(item)) : []
  }

  get title() {
    return this.data.title
  }

  get subtitle() {
    return this.data.subtitle
  }

  get seoDesc() {
    return this.data.seo_description
  }

  get thumbnail() {
    return this.data.thumbnail
  }

  get vehicles() {
    return this.vehiclesEntity
  }

  get html() {
    return this.data.html
  }

  get htmlJSON() {
    return this.data.body
  }

  get createdAt() {
    return this.data.created_at
  }

  get updatedAt() {
    return this.data.updated_at
  }

  get lastChanged() {
    return this.updatedAt || this.createdAt
  }

  get isTrending() {
    return this.data.trend
  }

  get status() {
    return this.data.status
  }

  get statusTxt() {
    return this.data.status === 'listed' ? 'Published' : 'Draft'
  }

  static get LISTED_STATUS() {
    return 'listed'
  }

  static get UNLISTED_STATUS() {
    return 'unlisted'
  }

}
