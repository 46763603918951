import { HttpFabric } from '@tools/http'

const http = HttpFabric.getFetchHttp()

export const getUserById = async () => {
  return http.get(`/api/users`)
}

export const postLogin = async (username, password) => {
  return http.post('/api/login', { username, password })
}
