import { useState } from 'react'

const PAGE_AMOUNT = 15
const FIRST_PAGE = 1

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE)
  const [totalPages, setTotalPages] = useState(0)

  const onResetPagination = () => {
    setCurrentPage(FIRST_PAGE)
  }

  return {
    totalPages,
    currentPage,
    data: { offset: currentPage, limit: PAGE_AMOUNT },
    setCurrentPage,
    setTotalPages,
    onResetPagination
  }
}
