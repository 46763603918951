import { useQuery } from 'react-query'
import { getVehicles } from '@repositories/vehicles'
import { Vehicle } from '@domain/vehicle'

const KEY = 'vehicles'

export function useGetVehicles(pagination, filters, options) {
  return useQuery(
    [KEY, pagination.offset, filters.brand, filters.model, filters.year, filters.search],
    async () => {
      const vehiclesResult = await getVehicles(pagination, filters)

      if (vehiclesResult.isOk()) {
        const list = await vehiclesResult.value.json()
        return {
          vehicles: list.data.map(item => new Vehicle(item)),
          pagination: {
            total: list.total,
            pages: list.last_page,
          }
        }
      }

      throw new Error('Can not get vehicles.')
    },
    options,
  )
}
