import { useQuery } from 'react-query'
import { getArticles } from '@repositories/articles'
import { Article } from '@domain/article'

const KEY = 'articles'

export function useGetArticles(pagination, filters, options) {
  return useQuery(
    [KEY, pagination.offset, filters.search],
    async () => {
      const articlesResult = await getArticles(pagination, filters)

      if (articlesResult.isOk()) {
        const list = await articlesResult.value.json()
        return {
          articles: list.data.map(item => new Article(item)),
          pagination: {
            total: list.total,
            pages: list.last_page,
          }
        }
      }

      throw new Error('Can not get articles.')
    },
    options,
  )
}
