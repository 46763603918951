import { jetsLocation } from '@application/locations'

import { ControlPanel } from '../../../_components_/control-panel'
import { useControlPanel } from './hook'

export const JetControlPanel = (props) => {
  const { jet, isEditMode, clickSaveBtn, disabledSaving } = props
  const { handlers } = useControlPanel(jet)

  return (
    <ControlPanel
      isPreviewAvailable={false}
      confirmBtnTxt="Delete jet-ski"
      deleteTxt={`Are you sure you want to delete jet-ski ${jet?.title}?`}
      location={jetsLocation}
      isEditMode={isEditMode}
      isDisabledSaving={disabledSaving}
      onClickSaveBtn={clickSaveBtn}
      onRemove={handlers.onRemoveJet}
    />
  )
}
