import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon } from '@blueprintjs/core'
import classNames from 'classnames'

import styles from './index.module.scss'

export const ListItem = ({ children, title, preview, to, className }) => {
  return (
    <Link to={to}>
      <Button className={classNames(styles.item, 'bp3-fill')} minimal>
        <div className={classNames(styles.itemWrap, className)}>
          <div className={styles.itemTitle}>
            <img className={styles.itemImg} src={preview} alt="car" />
            <p>{title}</p>
          </div>
          {children}
          <Icon className={styles.itemEdit} icon="edit" />
        </div>
      </Button>
    </Link>
  )
}
