import { useQuery } from 'react-query'
import { getArticle } from '@repositories/articles'
import { Article } from '@domain/article'

export function useGetArticle(id, options) {
  return useQuery(
    `article/${id}`,
    async () => {
      const articleResult = await getArticle(id)

      if (articleResult.isOk()) {
        const data = await articleResult.value.json()
        return new Article(data)
      }

      throw new Error(`Can not get article with id: ${id}.`)
    },
    options,
  )
}
