import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Article } from '@domain/article'
import { ArticlePayload } from '@domain/article/payload'
import { updateArticle } from '@repositories/articles'
import { useEditorWithImage } from '@application/hooks/editor-with-image'
import { useGetArticlePreview } from '@application/hooks/http/get-article-preview'
import { articlesLocation } from '@application/locations'

export function useEditArticlePreview() {
  const navigate = useNavigate()
  const { id: articleId } = useParams()
  const { initEditor, getEditorData } = useEditorWithImage()

  const [isEditingAvailable, setEditingStatus] = useState(false)

  const articleQuery = useGetArticlePreview(articleId, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (article) => {
      setEditingStatus(true)
      initEditor(article.htmlJSON || article.html)
    }
  })

  const onUpdateArticle = async (payload) => {
    const data = await _onArticleRequest(payload)
    if (payload.status === Article.LISTED_STATUS) {
      navigate(articlesLocation)
    } else if (data?.id) {
      window.open(ArticlePayload.createPreviewUrl(data.id), '_blank').focus()
    }
  }

  const _onArticleRequest = async (payload) => {
    const editorData = await getEditorData()
    const result = await updateArticle(articleId, new ArticlePayload({...payload, editorData}).DTO)
    if (result.isErr()) {
      throw result.value
    }
    return result.value.json()
  }

  return {
    state: {
      article: articleQuery.data,
      isEditingAvailable
    },
    handlers: {
      onUpdateArticle
    }
  }
}
