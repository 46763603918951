import { useRef, useState } from 'react'
import { Article } from '@domain/article'
import { createPhoto } from '@repositories/photos'
import { useFileReader } from '@application/hooks/file-reader'

import { showFailNotification } from '../../_components_/fail-notification'

const PHOTO_UPLOAD_BUCKET = 'thumbnails'
const TREND_ARTICLES_FILLED_ERROR = 'trend articles limit exceeded'
const ERROR_MESSAGE = 'Something went wrong. Please try again later'

export function useArticleForm(article, onSaveArticle) {
  const fileReader = useFileReader()
  const [title, setTitle] = useState(article ? article.title : '')
  const [subtitle, setSubtitle] = useState(article ? article.subtitle : '')
  const [seoDesc, setSeoDesc] = useState(article ? article.seoDesc : '')
  const [thumbnailUrl, setThumbnailUrl] = useState(article ? article.thumbnail : '')
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [vehicles, setVehicles] = useState(article ? article.vehicles : [])
  const [isTrending, setIsTrending] = useState(article ? article.isTrending : false)
  const [isShowReplaceConfirmation, setReplaceConfirmationStatus] = useState(false)

  const isThumbnailUploadedRef = useRef(!!article)
  const statusRef = useRef(Article.LISTED_STATUS)

  const onTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const onSubtitleChange = (event) => {
    setSubtitle(event.target.value)
  }

  const onSeoDescChange = (event) => {
    setSeoDesc(event.target.value)
  }

  const onThumbnailChange = async (file) => {
    isThumbnailUploadedRef.current = false
    setThumbnailFile(file)
    const url = await fileReader(file)
    setThumbnailUrl(url)
  }

  const onRemoveThumbnail = () => {
    setThumbnailUrl('')
    setThumbnailFile(null)
  }

  const onIsTrendingChange = (event) => {
    setIsTrending(event.target.checked)
  }

  const onOpenReplaceConfirmation = () => {
    setReplaceConfirmationStatus(true)
  }

  const onCloseReplaceConfirmation = () => {
    setReplaceConfirmationStatus(false)
  }

  const onClickSavePreviewBtn = async () => {
    statusRef.current = Article.UNLISTED_STATUS
    await onClickSaveBtn()
  }

  const onClickSaveBtn = async () => {
    onCloseReplaceConfirmation()
    try {
      await _onTryToSaveArticle()
    } catch (error) {
      if (_isTrendArticleFilled(error)) {
        onOpenReplaceConfirmation()
      } else {
        showFailNotification(ERROR_MESSAGE)
      }
    }
  }

  const _onTryToSaveArticle = async () => {
    let thumbnail = thumbnailUrl
    if (_isNeedToUploadThumbnail()) {
      thumbnail = await _uploadThumbnailPhoto()
      setThumbnailUrl(thumbnail)
    }
    if (thumbnail) {
      const payload = {
        title,
        subtitle,
        seoDesc,
        vehicles,
        isTrending,
        thumbnail,
        status: statusRef.current
      }
      await onSaveArticle(payload)
    }
  }

  const _uploadThumbnailPhoto = async () => {
    const result = await createPhoto(thumbnailFile, PHOTO_UPLOAD_BUCKET)
    if (result.isOk()) {
      isThumbnailUploadedRef.current = true
      const data = await result.value.json()
      return data.url[0]
    }
  }

  const _isNeedToUploadThumbnail = () => {
    return !isThumbnailUploadedRef.current
  }

  const _isTrendArticleFilled = (error) => {
    return error.body.error === TREND_ARTICLES_FILLED_ERROR
  }

  return {
    state: {
      isSavingAvailable: title && subtitle && thumbnailUrl,
      isEditMode: !!article,
      isShowReplaceConfirmation,
      title,
      subtitle,
      seoDesc,
      thumbnailUrl,
      thumbnailFile,
      isTrending,
      vehicles,
    },
    handlers: {
      onTitleChange,
      onSubtitleChange,
      onSeoDescChange,
      onThumbnailChange,
      onIsTrendingChange,
      onRemoveThumbnail,
      onOpenReplaceConfirmation,
      onCloseReplaceConfirmation,
      onClickSaveBtn,
      onClickSavePreviewBtn,
      setVehicles,
    }
  }
}
