import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArticlePayload } from '@domain/article/payload'
import { Article } from '@domain/article'
import { createArticle } from '@repositories/articles'
import { useEditorWithImage } from '@application/hooks/editor-with-image'
import { articlesLocation, getArticlePreviewLocation } from '@application/locations'

export function useCreateArticle() {
  const navigate = useNavigate()
  const { initEditor, getEditorData } = useEditorWithImage()

  const onCreateArticle = async (payload) => {
    let successPath = ''
    const data = await _onArticleRequest(payload)
    if (payload.status === Article.LISTED_STATUS) {
      successPath = articlesLocation
    } else if (data?.id) {
      successPath = getArticlePreviewLocation(data.id)
      window.open(ArticlePayload.createPreviewUrl(data.id), '_blank').focus()
    }
    return navigate(successPath)
  }

  const _onArticleRequest = async (payload) => {
    const editorData = await getEditorData()
    const result = await createArticle(new ArticlePayload({...payload, editorData}).DTO)
    if (result.isErr()) {
      throw result.value
    }
    return result.value.json()
  }

  useEffect(() => {
    initEditor()
  }, [])

  return {
    handlers: {
      onCreateArticle,
    }
  }
}
