import Nav from '../_components_/nav'
import { JetForm } from '../_features_/jet-form'
import { useCreateJet } from './hook'

export const JetCreatePage = () => {
  const { handlers } = useCreateJet()

  return (
    <>
      <Nav />
      <JetForm onSaveJet={handlers.onCreateJet} />
    </>
  )
}