import { Navigate, Route, Routes } from 'react-router-dom'
import { useAppState } from '@application/hooks/contexts/app-state'
import {
  vehicleListLocation,
  jetsLocation,
  addJetLocation,
  jetLocation,
  loginLocation,
  vehicleLocation,
  articlesLocation,
  articleLocation,
  articlePreviewLocation,
  createArticleLocation
} from '@application/locations'

import { LoginPage } from './login'
import { VehicleListPage } from './vehicle-list'
import { VehiclePage } from './vehicle'
import { ArticlesPage } from './articles'
import { CreateArticlePage } from './article-create'
import { EditArticlePage } from './article-edit'
import { EditArticlePreviewPage } from './article-edit-preview'
import { JetsListPage } from './jets-list'
import { JetCreatePage } from './jet-create'
import { EditJetPage } from './jet-edit'

export const AppRoutes = () => {
  const appState = useAppState()

  if (appState.state === 'un-auth') {
    return (
      <Routes>
        <Route path="*" element={<Navigate to={loginLocation} />} />
        <Route path={loginLocation} element={<LoginPage />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate to={vehicleListLocation} />} />
      <Route path={vehicleListLocation} element={<VehicleListPage />} />
      <Route path={vehicleLocation} element={<VehiclePage />} />
      <Route path={articlesLocation} element={<ArticlesPage />} />
      <Route path={createArticleLocation} element={<CreateArticlePage />} />
      <Route path={articleLocation} element={<EditArticlePage />} />
      <Route path={articlePreviewLocation} element={<EditArticlePreviewPage />} />
      <Route path={jetsLocation} element={<JetsListPage />} />
      <Route path={addJetLocation} element={<JetCreatePage />} />
      <Route path={jetLocation} element={<EditJetPage />} />
    </Routes>
  )
}
