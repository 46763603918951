import { useMemo } from 'react'

export const useVehiclesFilter = (allVehicles, selectedBrand) => {
  const brands = useMemo(
    () => ['Make', ...new Set(allVehicles.map(item => item.make.toLowerCase()))],
    [allVehicles]
  )
  const models = useMemo(
    () => [
      'Model',
      ...new Set(
        allVehicles
          .filter(item => item.make.toLowerCase() === selectedBrand)
          .map(item => item.model.toLowerCase().replace('-', ' '))
      )
    ],
    [allVehicles, selectedBrand]
  )
  const years = useMemo(
    () => [
      'Year',
      ...new Set(
        allVehicles
          .filter(item => item.make.toLowerCase() === selectedBrand)
          .map(item => item.year).sort((a, b) => a - b)
      )
    ],
    [allVehicles, selectedBrand])


  return {
    state: {
      brands,
      models,
      years,
    }
  }
}
