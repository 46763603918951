import { YoutubeService } from '../../application/services/youtube.service'

export class VehiclePayload {
  constructor(payload) {
    this.data = {
      description: {
        fuel_type: payload.fuelType,
        video_url: YoutubeService.prepareVideoLink(payload.videoLink),
        number_seats: payload.seatsNumber,
        number_doors: payload.doorsNumber,
        body: payload.editorData
      },
      most_popular: payload.popularCheckbox
    }
  }

  static getFuelOptions() {
    return ['Electro', 'Gas', 'Hybrid', 'Diesel']
  }

  static getSeatsOptions() {
    return ['2 seats', '4 seats', '5 seats', '6 seats', '7 seats', '8 seats', '12 seats', '15 seats']
  }

  static getDoorsOptions() {
    return ['2 doors', '4 doors', '5 doors']
  }

  get DTO() {
    return this.data
  }

}
