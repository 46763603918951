import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import classNames from 'classnames'
import Nav from '@components/nav'
import { getJetLocation } from '@application/locations'

import { addJetLocation } from '../../application/locations'
import { List } from '../_components_/list'
import { ListItem } from '../_components_/list-item'
import { useJetsList } from './hook'
import styles from './index.module.scss'

export const JetsListPage = () => {
  const { state } = useJetsList()
  return (
    <>
      <Nav />
      <div className={styles.container}>
        <List
          header={
            <div className={styles.header}>
              <h1>Jet-skis</h1>
              <Link to={addJetLocation}>
                <Button className={classNames(styles.headerBtn, 'bp3-intent-primary')} large>Add jet-ski</Button>
              </Link>
            </div>
          }
          labels={(
            <div className={styles.label}>
              <div className={styles.labelWrap}>
                <span>Jet-ski</span>
                <span>Price</span>
                <span>Edit jet ski</span>
              </div>
            </div>
          )}
          renderItem={(jet) => (
            <ListItem
              className={styles.jets}
              key={jet.id}
              to={getJetLocation(jet.id)}
              title={jet.title}
              preview={jet.thumbnail}
            >
              <p className={styles.price}>{jet.price}</p>
            </ListItem>
          )}
          items={state.jets}
          onPageChange={data => handlers.setCurrentJetsPage(data.selected + 1)}
          pageCount={state.totalJetsPage}
          forcePage={state.currentJetsPage - 1}
          searchTerm={state.searchTerm}
        />
      </div>
    </>
  )
}