import { Entity } from '../_entity_'

export class ArticlePayload {
  constructor(payload) {
    this.data = {
      title: payload.title,
      subtitle: payload.subtitle,
      seo_description: payload.seoDesc,
      body: payload.editorData,
      vehicles: payload.vehicles.map(vehicle => vehicle.DTO),
      trend: payload.isTrending,
      thumbnail: payload.thumbnail,
      status: payload.status,
    }
  }

  static createPreviewUrl(id) {
    return `https://techiiamotorsports.com/article-preview?id=${id}`
  }

  get DTO() {
    return this.data
  }

}
