import { Entity } from './_entity_'

export class User extends Entity {

  constructor(data) {
    super(data)
  }

  get username() {
    return this.data.username
  }

}
