
export class LocalStorageService  {
  save(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  get(key) {
    const data = localStorage.getItem(key);
    if (typeof data === 'string') return data
    return data ? JSON.parse(data) : null
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}
