import classNames from 'classnames'

import { useFileInput } from './hook'
import styles from './index.module.scss'

export const FileInput = (props) => {
  const { state, handlers } = useFileInput(props)
  const { placeholder, className, onChange, id, multiple } = props

  return (
    <>
      <label
        className={classNames(styles.input, className)}
        htmlFor={id}
        onDragOver={event => event.preventDefault()}
        onDragEnter={event => event.preventDefault()}
        onDrop={handlers.onDropFile}
      >
        {placeholder}
      </label>
      <input
        ref={state.fileInputRef}
        id={id}
        type="file"
        onChange={handlers.onFileChange}
        hidden
        multiple={multiple}
      />
    </>
  )
}
