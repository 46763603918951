export class Entity {

  constructor(data) {
    this.data = data
  }

  get id() {
    return this.data.id
  }

  get DTO() {
    return this.data
  }

}
