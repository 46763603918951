import { Entity } from '../_entity_'

const THUMBNAIL_SIZE = '170x102'

export class Vehicle extends Entity {

  constructor(data) {
    super({
      ...data,
      photos: data.photo.map(photo => photo.thumbnails[THUMBNAIL_SIZE] || photo.originalImageUrl),
    })
  }

  get name() {
    return `${this.make} ${this.model} ${this.year}`
  }

  get make() {
    return this.data.make ?? ''
  }

  get model() {
    return this.data.model ?? ''
  }

  get year() {
    return this.data.year ?? 0
  }

  get photos() {
    return this.data.photos
  }

  get thumbnail() {
    return this.photos[0]
  }

  get minPrice() {
    if (this.data.average_monthly_price) return this.data.average_monthly_price
    if (!this.data.average_daily_price) return 0
    if (!this.data.monthly_discount_percentage) return this.data.average_daily_price
    return Math.round(this.data.average_daily_price * (1 - this.data.monthly_discount_percentage / 100))
  }

  get link() {
    return this.data.url
  }

  get description() {
    return this.data.description?.html
  }

  get descriptionJSON() {
    return this.data.description?.body
  }

  get fuelType() {
    if (this.data.description) return this.data.description.fuel_type
    return null
  }

  get numberOfSeats() {
    if (this.data.description) return this.data.description.number_seats
    return null
  }

  get numberOfDoors() {
    if (this.data.description) return this.data.description.number_doors
    return null
  }

  get videoLink() {
    if (this.data.description) return this.data.description.video_url
    return ''
  }

  get isPopular() {
    return this.data?.most_popular
  }
}
