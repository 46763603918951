export const formatDate = (dateStr) => {
  const date = new Date(dateStr)
  const monthDay = addZeroToOneNumber(date.getDate())
  const month = addZeroToOneNumber(date.getMonth() + 1)
  return `${monthDay}.${month}.${date.getFullYear()}`
}

export const addZeroToOneNumber = (number) => {
  return number < 10 ? `0${number}` : number
}
