import { usePagination } from '@application/hooks/pagination'
import { useSearchTherm } from '@application/hooks/search-therm'
import { useGetJets } from '@application/hooks/http/get-jets'

export const useJetsList = () => {
  const {
    data: paginationData,
    currentPage,
    totalPages,
    setTotalPages,
    setCurrentPage,
    onResetPagination,
  } = usePagination()
  const { searchTerm, debouncedSearchTerm, onSearchTermChange } = useSearchTherm({ onChange: onResetPagination })

  const jetsQuery = useGetJets(
    paginationData,
    { search: debouncedSearchTerm },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        setTotalPages(data.pagination.pages)
      }
    }
  )

  const jets = jetsQuery.data?.jets ?? []

  return {
    state: {
      jets,
      currentJetsPage: currentPage,
      searchTerm,
      totalJetsPage: totalPages
    },
    handlers: {
      setCurrentJetsPage: setCurrentPage,
      onSearchTermChange
    }
  }
}