import { useNavigate } from 'react-router-dom'
import { removeJet } from '@repositories/jets'
import { jetsLocation } from '@application/locations'

export function useControlPanel(jet) {
  const navigate = useNavigate()

  const onRemoveJet = async () => {
    if (jet?.id) {
      await removeJet(jet.id)
      navigate(jetsLocation)
    }
  }
  return {
    handlers: {
      onRemoveJet,
    }
  }
}
