export const loginLocation = '/login'

export const vehicleListLocation = '/vehicle-list'
export const vehicleLocation = '/vehicle-list/:id'

export const articlesLocation = '/articles'
export const articleLocation = '/articles/:id'
export const articlePreviewLocation = '/articles-preview/:id'
export const createArticleLocation = '/article-create'

export const jetsLocation = '/jets-list'
export const jetLocation = '/jets-list/:id'
export const addJetLocation = '/add-jet'

export const getVehicleLocation = (id) => {
  return `/vehicle-list/${id}`
}

export const getArticleLocation = (id) => {
  return `/articles/${id}`
}

export const getArticlePreviewLocation = (id) => {
  return `/articles-preview/${id}`
}

export const getJetLocation = (id) => {
  return `/jets-list/${id}`
}
