const BASE_YOUTUBE_URL = 'https://www.youtube.com/embed/'
const YOUTUBE_URL_REGEXPS = [
    /^https:\/\/(www.)?youtube.com\/watch\?v=(.+)&?/,
    /^https:\/\/(www.)?youtube.com\/shorts\/(.+)\??/,
    /^https:\/\/(www.)?youtu.be\/(.+)\??/
]
const VIDEO_ID_URL_INDEX = 2

export class YoutubeService {
    static prepareVideoLink(url) {
        for (const urlRegexp of YOUTUBE_URL_REGEXPS) {
            if (urlRegexp.test(url)) {
                const result = urlRegexp.exec(url)
                url = `${BASE_YOUTUBE_URL}${result[VIDEO_ID_URL_INDEX]}`
                break
            }
        }
        return url
    }
}