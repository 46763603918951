import { Link } from 'react-router-dom'
import { Button, Alert, Intent } from '@blueprintjs/core'

import { useControlPanel } from './hook'
import styles from './index.module.scss'

export const ControlPanel = (props) => {
  const {
    confirmBtnTxt,
    deleteTxt,
    location,
    isEditMode,
    isPreviewAvailable,
    isDisabledSaving,
    onClickSaveBtn,
    onClickSavePreviewBtn,
    onRemove
  } = props
  const { state, handlers } = useControlPanel()

  const deleteAlert = (
    <Alert
      cancelButtonText="Cancel"
      confirmButtonText={confirmBtnTxt}
      icon="trash"
      intent={Intent.DANGER}
      isOpen={state.isShowDeleteConfirmation}
      onCancel={handlers.onCloseDeleteConfirmation}
      onConfirm={onRemove}
    >
      <p>{deleteTxt}</p>
    </Alert>
  )

  return (
    <div className={styles.control}>
      <div className={styles.controlWrap}>
        <div>
          <Link to={location}><Button className="bp3-large">Cancel</Button></Link>
          {isEditMode && <Button className="bp3-large" onClick={handlers.onOpenDeleteConfirmation}>Delete</Button>}
        </div>
        <div>
          {isPreviewAvailable && (
            <Button onClick={onClickSavePreviewBtn} className="bp3-large bp3-outlined bp3-intent-primary">
              Save draft
            </Button>
          )}
          <Button onClick={onClickSaveBtn} className="bp3-intent-primary bp3-large" disabled={isDisabledSaving}>
            {isPreviewAvailable ? 'Publish' : 'Save changes'}
          </Button>
        </div>
        {deleteAlert}
      </div>
    </div>
  )
}
