import React from 'react'
import classNames from 'classnames'
import {  Tag } from '@blueprintjs/core'
import { Article } from '@domain/article'

import styles from './index.module.scss'

export const ArticlesStatus = ({ status, text, ...props }) => {
  return (
    <Tag {...props} className={classNames(styles.status, {
      [styles.isDraft]: status === Article.UNLISTED_STATUS
    })}>
      {text}
    </Tag>
  )
}
