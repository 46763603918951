import { FetchHttpService } from './fetch/fetch-http.service'
import { Result } from './result/index'

export const result = Result

export class HttpFabric {
    static getFetchHttp() {
        if (!this.fetchHttp) this.fetchHttp = new FetchHttpService(result)
        return this.fetchHttp
    }
}
