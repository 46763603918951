import {
  Button,
  TextArea,
  Checkbox,
  Label,
} from '@blueprintjs/core'

import { FileInput } from '../../_components_/file-input'
import { ArticlesStatus } from '../../_components_/article-status'
import { VehiclesSelect } from './vehicles-select'
import { ArticleControlPanel } from './control-panel'
import { ReplaceDialog } from './replace-dialog'
import { useArticleForm } from './hook'
import styles from './index.module.scss'

export const ArticleForm = ({ article, onSaveArticle, isPreviewAvailable }) => {
  const { state, handlers } = useArticleForm(article, onSaveArticle)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleWrap}>
          <h1 className={styles.title}>{state.isEditMode ? 'Edit article' : 'Create article'}</h1>
          {article && <ArticlesStatus large status={article.status} text={article.statusTxt} />}
        </div>
        <div className={styles.infoMain}>
          <div>
            <Label>
              Title
              <TextArea
                className={styles.textarea}
                max={70}
                min={10}
                value={state.title}
                onChange={handlers.onTitleChange}
                fill
              />
            </Label>
            <Label>
              Subtitle
              <TextArea
                className={styles.textarea}
                max={164}
                min={100}
                value={state.subtitle}
                onChange={handlers.onSubtitleChange}
                fill
              />
            </Label>
            <Label>
              Seo description
              <TextArea value={state.seoDesc} onChange={handlers.onSeoDescChange} fill />
            </Label>
            <p>Assign up to 3 cars</p>
            <VehiclesSelect value={state.vehicles} onChange={handlers.setVehicles} />
            <Checkbox checked={state.isTrending} onChange={handlers.onIsTrendingChange}>
              Show in Trending section
            </Checkbox>
          </div>

          <div className={styles.fileInput}>
            {state.thumbnailUrl ? (
              <>
                <img src={state.thumbnailUrl} alt="Article thumbnail" className={styles.fileInputImg} />
                <Button className={styles.fileInputRemove} icon="trash" onClick={handlers.onRemoveThumbnail} />
              </>
            ) : (
              <FileInput
                id="thumbnail"
                placeholder="Drag 'n' drop image here, or click to select file(png or jpg)"
                onChange={handlers.onThumbnailChange}
              />
            )}
          </div>

        </div>

        <div className={styles.infoGeneral} id="editor" />
      </div>
      <ArticleControlPanel
        article={article}
        disabledSaving={!state.isSavingAvailable}
        isEditMode={state.isEditMode}
        isPreviewAvailable={isPreviewAvailable}
        clickSaveBtn={handlers.onClickSaveBtn}
        clickSavePreviewBtn={handlers.onClickSavePreviewBtn}
      />
      <ReplaceDialog
        articleId={article?.id}
        isOpen={state.isShowReplaceConfirmation}
        clickSaveBtn={handlers.onClickSaveBtn}
        onClose={handlers.onCloseReplaceConfirmation}
      />
    </>
  )
}
