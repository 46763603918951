import { HttpFabric } from '@tools/http'

const http = HttpFabric.getFetchHttp()

export const getArticles = async (pagination, filters) => {
  let params = `status=listed,unlisted&offset=${pagination?.offset || 0}&limit=${pagination?.limit || 0}`
  if (filters?.search) params += `&search=${filters.search}`
  return http.get(`/api/articles?${params}`)
}

export const getTrendArticles = async () => {
  return http.get('/api/articles?trend=true')
}

export const getArticle = async (id) => {
  return http.get(`/api/articles/${id}?editorjs=true`)
}

export const getArticlePreview = async (id) => {
  return http.get(`/api/articles/${id}/preview?editorjs=true`)
}

export const createArticle = async (data) => {
  return http.post('/api/articles', data)
}

export const updateArticle = async (id, data) => {
  return http.put(`/api/articles/${id}`, data)
}

export const deleteArticle = async (id) => {
  http.remove(`/api/articles/${id}`)
}
