import { StorageFabric } from '../../storage'

export class FetchHttpService {
  constructor(result) {
    this.baseUrl = 'https://api.techiiamotorsports.com'
    this.result = result
    this.storage = StorageFabric.getLocalStorage()
  }

  async get(url) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: this._getAuthorizationHeader(),
      }
    })

    return this._processResponse(response)
  }

  async post(url, body) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      method: 'POST',
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this._getAuthorizationHeader(),
      }
    })

    return this._processResponse(response)
  }

  async postFile(url, body) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      method: 'POST',
      body: body,
      headers: {
        Authorization: this._getAuthorizationHeader(),
      }
    })

    return this._processResponse(response)
  }

  async put(url, body) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      method: 'PUT',
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this._getAuthorizationHeader(),
      }
    })

    return this._processResponse(response)
  }

  async patch(url, body) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      method: 'PATCH',
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this._getAuthorizationHeader(),
      }
    })

    return this._processResponse(response)
  }

  async remove(url) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this._getAuthorizationHeader(),
      }
    })

    return this._processResponse(response)
  }

  async _processResponse(response) {
    if (response.ok) {
      return this.result.ok(response)
    }

    try {
      const errorJson = await response.json()

      return this.result.err({
        code: response.status,
        body: errorJson
      })
    } catch {
      return this.result.err({
        code: response.status
      })
    }
  }

  _getAuthorizationHeader() {
    try {
      const token = JSON.parse(this.storage.get('token') || '')

      if (token) {
        return `Bearer ${token}`
      }

      return ''
    } catch {
      return ''
    }
  }
}
