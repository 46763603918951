import { useState, useMemo, useEffect } from 'react'
import { getTrendArticles, updateArticle } from '@repositories/articles'

export const DEFAULT_SELECT_VALUE = 'Select article to replace'

export function useReplaceDialog(articleId, clickSaveBtn) {
  const [trendArticles, setTrendArticles] = useState([])
  const [trendArticleToReplace, setTrendArticleToReplace] = useState('')

  const selectedTrendArticleID = useMemo(
    () => trendArticles.find(item => item.title === trendArticleToReplace)?.id,
    [trendArticleToReplace, trendArticles]
  )
  const trendArticlesOptions = useMemo(
    () => [DEFAULT_SELECT_VALUE].concat(trendArticles.map(item => item.title)),
    [trendArticles]
  )

  const onClickSaveBtn = async () => {
    if (selectedTrendArticleID) {
      const result = await updateArticle(selectedTrendArticleID, {
        trend: false
      })
      if (result.isOk()) {
        await clickSaveBtn()
      }
    }
  }

  const fetchTrendArticles = async () => {
    const result = await getTrendArticles()
    if (result.isOk()) {
      const list = await result.value.json()
      if (list.data) setTrendArticles(list.data)
    }
  }

  useEffect(() => {
    fetchTrendArticles()
  }, [])

  return {
    state: {
      trendArticleToReplace,
      trendArticlesOptions,
    },
    handlers: {
      onClickSaveBtn,
      setTrendArticleToReplace,
    }
  }
}
