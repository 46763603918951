import React from 'react'

import Nav from '../_components_/nav'
import { ArticleForm } from '../_features_/article-form'
import { useCreateArticle } from './hook'

export const CreateArticlePage = () => {
  const { handlers } = useCreateArticle()
  return (
    <>
      <Nav />
      <ArticleForm isPreviewAvailable onSaveArticle={handlers.onCreateArticle} />
    </>
  )
}
