import { Entity } from '../_entity_'

export class Jet extends Entity {

  constructor(data) {
    super(data)
  }

  get title() {
    return this.data.title
  }

  get photos() {
    return this.data.slider_photo
  }

  get thumbnail() {
    return this.data.thumbnail.url
  }

  get price() {
    return this.data.price
  }

  get html() {
    return this.data.html
  }

  get htmlJSON() {
    return this.data.body
  }

  get mainVideo() {
    return this.data.slider_video
  }

  get descVideo() {
    return this.data.description_video
  }

  get seoDesc() {
    return this.data.seo_tags
  }
}
