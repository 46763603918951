import { YoutubeService } from '../../application/services/youtube.service'

export class JetPayload {
  constructor(payload) {
    this.data = {
      title: payload.title,
      price: Number(payload.price),
      seo_tags: payload.seoDesc,
      body: payload.editorData,
      slider_photo: payload.photosUrls.map((url) => ({ url })),
      slider_video: YoutubeService.prepareVideoLink(payload.mainVideo),
      description_video: YoutubeService.prepareVideoLink(payload.descVideo),
    }
  }

  get DTO() {
    return this.data
  }

}
