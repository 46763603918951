import React from 'react'

import Nav from '../_components_/nav'
import { ArticleForm } from '../_features_/article-form'
import { useEditArticlePreview } from './hook'

export const EditArticlePreviewPage = () => {
  const { state, handlers } = useEditArticlePreview()
  return (
    <>
      <Nav />
      {state.isEditingAvailable && (
        <ArticleForm isPreviewAvailable onSaveArticle={handlers.onUpdateArticle} article={state.article} />
      )}
    </>
  )
}
