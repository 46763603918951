import { useQuery } from 'react-query'
import { getArticlePreview } from '@repositories/articles'
import { Article } from '@domain/article'

export function useGetArticlePreview(id, options) {
  return useQuery(
    `article/${id}/preview`,
    async () => {
      const articleResult = await getArticlePreview(id)

      if (articleResult.isOk()) {
        const data = await articleResult.value.json()
        return new Article(data)
      }

      throw new Error(`Can not get article preview with id: ${id}.`)
    },
    options,
  )
}
