import { Button, Dialog, HTMLSelect } from '@blueprintjs/core'

import { useReplaceDialog, DEFAULT_SELECT_VALUE } from './hook'
import styles from './index.module.scss'

export const ReplaceDialog = ({ articleId, clickSaveBtn, isOpen, onClose }) => {
  const { state, handlers } = useReplaceDialog(articleId, clickSaveBtn)

  return (
    <Dialog
      title="Show in Trending section"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.dialog}>
        <p className={styles.dialogText}>
          There are already 3 articles (maximum) in Trending section. If you want to show this article, select which article to replace:
        </p>
        <HTMLSelect
          fill
          value={!state.trendArticleToReplace ? DEFAULT_SELECT_VALUE : state.trendArticleToReplace}
          onChange={(event) => handlers.setTrendArticleToReplace(
            event.target.value === DEFAULT_SELECT_VALUE ? '' : event.target.value
          )}
          options={state.trendArticlesOptions}
        />
        <div className={styles.dialogFooter}>
          <Button onClick={onClose} className="bp3-large bp3-outlined bp3-intent-primary">
            Cancel
          </Button>
          <Button
            onClick={handlers.onClickSaveBtn}
            className="bp3-intent-primary bp3-large"
            disabled={!state.trendArticleToReplace}
          >
            Replace article
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
