import { useQuery } from 'react-query'
import { User } from '@domain/user'
import { StorageFabric } from '@tools/storage'
import { getUserById } from '@repositories/user'

const KEY = 'current-user'

export function useCurrentUser(options) {
  return useQuery(
    KEY,
    async () => {
      const storage = StorageFabric.getLocalStorage()
      const userID = storage.get('user-id')

      if (!userID) {
        throw new Error('There is not user id saved in local storage.')
      }

      const currentUserResult = await getUserById()

      if (currentUserResult.isOk()) {
        const dto = await currentUserResult.value.json()
        return new User(dto)
      }

      throw new Error(`Can not get user with id="${userID}".`)
    },
    options,
  )
}
