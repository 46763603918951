import InputSearch from '../../_components_/search-input'
import styles from './index.module.scss'

export default function ArticlesFilter({
 searchTerm,
 handleSearchTermChange,
}) {
  return (
    <InputSearch className={styles.search} value={searchTerm} onChange={handleSearchTermChange} />
  )
}
