import { useState } from 'react'
import { useDebounce } from '@application/hooks/debounced'

const SEARCH_DEBOUNCE_DELAY = 300

export const useSearchTherm = ({ onChange }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_DELAY)

  const onSearchTermChange = (event) => {
    onChange()
    setSearchTerm(event.target.value)
  }

  return {
    searchTerm,
    debouncedSearchTerm,
    onSearchTermChange,
  }
}
