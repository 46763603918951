import { useGetArticles } from '@application/hooks/http/get-articles'
import { usePagination } from '@application/hooks/pagination'
import { useSearchTherm } from '@application/hooks/search-therm'

export const useArticlesList = () => {
  const {
    data: paginationData,
    currentPage,
    totalPages,
    setTotalPages,
    setCurrentPage,
    onResetPagination,
  } = usePagination()
  const { searchTerm, debouncedSearchTerm, onSearchTermChange } = useSearchTherm({ onChange: onResetPagination })

  const articlesQuery = useGetArticles(
    paginationData,
    { search: debouncedSearchTerm },
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        setTotalPages(data.pagination.pages)
      }
    }
  )

  const articles = articlesQuery.data?.articles ?? []

  return {
    state: {
      articles,
      searchTerm,
      totalArticlesPage: totalPages,
      currentArticlesPage: currentPage
    },
    handlers: {
      onSearchTermChange,
      setCurrentArticlesPage: setCurrentPage
    }
  }
}
