import React, { useMemo } from 'react'
import { HTMLSelect, InputGroup, Checkbox } from '@blueprintjs/core'
import { useVehicleOption } from './hook'
import styles from '../index.module.scss'

export default function VehicleOptions({
  selectedFuelType,
  selectedSeatsNumber,
  selectedDoorsNumber,
  videoLink,
  onSelectFuelType,
  onSelectSeatsNumber,
  onSelectDoorsNumber,
  onVideoUrlChange,
  isPopular,
  handleIsPopularChange
}) {
  const { state } = useVehicleOption()

  const onSelectChange = (defaultOption, onSelect) => (event) => {
    const { value } = event.target
    if (value === defaultOption) return onSelect('')
    onSelect(value)
  }

  const onFuelTypeChange = useMemo(() => onSelectChange(state.fuel[0], onSelectFuelType), [])

  const onSeatsNumberChange = useMemo(() => onSelectChange(state.seats[0], onSelectSeatsNumber), [])

  const onDoorsNumberChange = useMemo(() => onSelectChange(state.doors[0], onSelectDoorsNumber), [])

  return(
    <div className={styles.carSelectors}>
      <HTMLSelect options={state.fuel} value={selectedFuelType} onChange={onFuelTypeChange} />
      <HTMLSelect options={state.seats} value={selectedSeatsNumber} onChange={onSeatsNumberChange} />
      <HTMLSelect options={state.doors} value={selectedDoorsNumber} onChange={onDoorsNumberChange} />
      <InputGroup
        leftIcon="video"
        placeholder="Paste video link"
        value={videoLink}
        onChange={event => onVideoUrlChange(event.target.value)}
      />
      <Checkbox
        checked={isPopular}
        label="Most popular"
        onChange={handleIsPopularChange}
      />
    </div>
  )
}
