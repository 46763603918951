import { Fragment } from 'react'
import { Label, InputGroup, Button, Icon } from '@blueprintjs/core'

import { FileInput } from '../../_components_/file-input'
import { JetControlPanel } from './control-panel'
import { useJetForm } from './hook'
import styles from './index.module.scss'

export const JetForm = ({ jet, onSaveJet }) => {
  const { state, handlers } = useJetForm(jet, onSaveJet)

  const renderPhotoInput = (index) => {
    const url = state.photosUrls[index]
    const isFirstElement = index === 0
    return url ? (
      <div key={index} className={styles.sliderImgContainer }>
        <img src={url} alt="Article thumbnail" className={styles.sliderImg} />
        <Button className={styles.sliderRemove} icon="trash" onClick={() => handlers.onRemovePhoto(index)} />
        {isFirstElement && <span className={styles.sliderMainLabel}>Main</span>}
      </div>
    ) : (
      <FileInput
        multiple
        key={index}
        className={styles.sliderInput}
        id={`thumbnail${index}`}
        placeholder={isFirstElement ? `Drag 'n' drop images here, or click to select` : <Icon icon="camera"  />}
        onChange={(file) => handlers.onPhotoChange(file, index)}
      />
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleWrap}>
          <h1 className={styles.title}>Add jet-ski</h1>
        </div>
        <div className={styles.info}>
          <div className={styles.infoMain}>
            <Label>
              Jet ski name
              <InputGroup
                placeholder="Enter jet ski name"
                className={styles.textarea}
                value={state.title}
                onChange={handlers.onTitleChange}
                fill
              />
            </Label>
            <Label>
              Price per day
              <InputGroup
                className={styles.textarea}
                placeholder="Enter jet ski price per day"
                value={state.price}
                onChange={handlers.onPriceChange}
                fill
              />
            </Label>
            <Label>
              Seo description
              <InputGroup
                className={styles.textarea}
                placeholder="Enter seo description"
                value={state.seoDesc}
                onChange={handlers.onSeoDescChange}
                fill
              />
            </Label>
            <Label>
              Description video
              <InputGroup
                className={styles.textarea}
                placeholder="Paste video link"
                leftIcon="video"
                value={state.descVideo}
                onChange={handlers.onDescVideoChange}
                fill
              />
            </Label>
            <Label>
              Slider video
              <InputGroup
                className={styles.textarea}
                placeholder="Paste video link"
                leftIcon="video"
                value={state.mainVideo}
                onChange={handlers.onMainVideoChange}
                fill
              />
            </Label>
          </div>

          <p className={styles.sliderLabel}>Slider photos</p>
          <p className={styles.sliderTxt}>First photo will be set as main. Drag photos to reorder.</p>
          <div className={styles.slider}>
            {state.photoUploaders.map(renderPhotoInput)}
          </div>
        </div>

        <div className={styles.infoGeneral} id="editor" />
      </div>
      <JetControlPanel
        jet={jet}
        disabledSaving={!state.isSavingAvailable}
        isEditMode={state.isEditMode}
        clickSaveBtn={handlers.onClickSaveBtn}
      />
    </>
  )
}