import { FormGroup, InputGroup } from '@blueprintjs/core'

export default function InputSearch({ className, ...props }) {
  return (
    <FormGroup
      labelFor="search"
      className={className}
    >
      <InputGroup
        {...props}
        id="search"
        placeholder="Search"
        leftIcon="search"
        type="search"
      />
    </FormGroup>
  )
}
