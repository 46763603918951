import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ArticlePayload } from '@domain/article/payload'
import { updateArticle } from '@repositories/articles'
import { useEditorWithImage } from '@application/hooks/editor-with-image'
import { useGetArticle } from '@application/hooks/http/get-article'
import { articlesLocation } from '@application/locations'

export function useEditArticle() {
  const navigate = useNavigate()
  const { id: articleId } = useParams()
  const { initEditor, getEditorData } = useEditorWithImage()

  const [isEditingAvailable, setEditingStatus] = useState(false)

  const articleQuery = useGetArticle(articleId, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (article) => {
      setEditingStatus(true)
      initEditor(article.htmlJSON || article.html)
    }
  })

  const onUpdateArticle = async (payload) => {
    const editorData = await getEditorData()
    const result = await updateArticle(articleId, new ArticlePayload({...payload, editorData}).DTO)
    if (result.isErr()) {
      throw result.value
    }
    navigate(articlesLocation)
  }

  return {
    state: {
      article: articleQuery.data,
      isEditingAvailable
    },
    handlers: {
      onUpdateArticle
    }
  }
}
