import ReactPaginate from 'react-paginate'
import classNames from 'classnames'

import styles from './index.module.scss'

function Pagination({ className, pageCount, forcePage, onPageChange }) {
  return (
    <div className={classNames(styles.container, className)}>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        renderOnZeroPageCount={null}
        onPageChange={onPageChange}
        pageCount={pageCount}
        forcePage={forcePage}
        containerClassName={styles.pagination}
        pageClassName={styles.paginationItem}
        activeClassName={styles.paginationActive}
        nextClassName={styles.paginationSymbol}
        previousClassName={styles.paginationSymbol}
        breakClassName={styles.paginationSymbol}
      />
    </div>
  )
}

export default Pagination
