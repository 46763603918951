import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { JetPayload } from '@domain/jet/payload'
import { updateJet } from '@repositories/jets'
import { useEditorWithImage } from '@application/hooks/editor-with-image'
import { useGetJet} from '@application/hooks/http/get-jet'
import { jetsLocation } from '@application/locations'

import { useUploadPhotos } from '../../application/hooks/useUploadPhotos'

export function useEditJet() {
  const navigate = useNavigate()
  const { id: jetId } = useParams()
  const { initEditor, getEditorData } = useEditorWithImage()
  const { uploadPhotos } = useUploadPhotos()

  const [isEditingAvailable, setEditingStatus] = useState(false)

  const jetQuery = useGetJet(jetId, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (jet) => {
      setEditingStatus(true)
      initEditor(jet.htmlJSON || jet.html)
    }
  })

  const onUpdateJet = async (payload) => {
    const editorData = await getEditorData()
    const uploadedPhotosUrls = await uploadPhotos(payload.photosFiles)
    const result = await updateJet(jetId, new JetPayload({
      ...payload,
      photosUrls: payload.photosUrls.filter((url) => url.includes('http')).concat(uploadedPhotosUrls),
      editorData
    }).DTO)
    navigate(jetsLocation)
  }

  return {
    state: {
      jet: jetQuery.data,
      isEditingAvailable
    },
    handlers: {
      onUpdateJet
    }
  }
}
