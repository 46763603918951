import { useGetAllVehicles } from '@application/hooks/http/get-all-vehicles'

export function useVehiclesSelect(value, onChange) {
  const vehiclesQuery = useGetAllVehicles({
    refetchOnWindowFocus: false,
    retry: false,
  })

  const onSelectVehicle = (vehicle) => {
    if (isVehicleSelected(vehicle)) return onRemoveSelectedVehicle(vehicle.id)
    onChange(list => list.concat(vehicle))
  }

  const onRemoveSelectedVehicle = (id) => {
    onChange(list => list.filter(item => id !== item.id))
  }

  const onClearVehicles = () => {
    onChange([])
  }

  const isVehicleSelected = (vehicle) => {
    return value.find(item => isVehiclesEqual(item, vehicle))
  }

  const isVehiclesEqual = (vehicleA, vehicleB) => {
    return vehicleA.id === vehicleB.id
  }

  const isVehicleQueryMatch = (query, vehicle) => {
    return vehicle.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
  }

  return {
    state: {
      vehicles: vehiclesQuery.data ?? [],
    },
    handlers: {
      onSelectVehicle,
      onRemoveSelectedVehicle,
      onClearVehicles,
      isVehicleSelected,
      isVehiclesEqual,
      isVehicleQueryMatch
    }
  }
}
