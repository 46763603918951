import React from 'react'
import Nav from '@components/nav'
import { getVehicleLocation } from '@application/locations'

import { List } from '../_components_/list'
import { ListItem } from '../_components_/list-item'
import VehiclesFilter from './filter'
import { useVehiclesList } from './hook'
import styles from './index.module.scss'

export const VehicleListPage = () => {
  const { state, handlers } = useVehiclesList()
  return (
    <>
      <Nav />
      <div className={styles.container}>
        <List
          header={(
            <>
              <h1 className={styles.title}>Cars’ description</h1>
              <VehiclesFilter
                selectedBrand={state.selectedBrand}
                selectedModel={state.selectedModel}
                selectedYear={state.selectedYear}
                onSelectBrand={handlers.selectBrand}
                onSelectModel={handlers.selectModel}
                onSelectYear={handlers.selectYear}
                searchTerm={state.searchTerm}
                vehicles={state.vehicles}
                allVehicles={state.allVehicles}
                handleSearchTermChange={handlers.onSearchTermChange}
                handleClearAll={handlers.handleClearAll}
              />
            </>
          )}
          labels={(
            <div className={styles.label}>
              <div className={styles.labelWrap}>
                <span>Car</span>
                <span>Edit description</span>
              </div>
            </div>
          )}
          renderItem={(vehicle) => (
            <ListItem
              key={vehicle.id}
              to={getVehicleLocation(vehicle.id)}
              title={vehicle.name}
              preview={vehicle.thumbnail}
            />
          )}
          items={state.vehicles}
          onPageChange={data => handlers.setCurrentVehiclesPage(data.selected + 1)}
          pageCount={state.totalVehiclesPage}
          forcePage={state.currentVehiclesPage - 1}
          searchTerm={state.searchTerm}
        />
      </div>
    </>
  )
}
