import {useRef, useState} from 'react'
import { useFileReader } from '@application/hooks/file-reader'

export function useJetForm(jet, onSaveJet) {
  const fileReader = useFileReader()
  const [title, setTitle] = useState(jet ? jet.title : '')
  const [seoDesc, setSeoDesc] = useState(jet ? jet.seoDesc : '')
  const [price, setPrice] = useState(jet ? jet.price : '')
  const [mainVideo, setMainVideo] = useState(jet ? jet.mainVideo : '')
  const [descVideo, setDescVideo] = useState(jet ? jet.descVideo : '')
  const [photosUrls, setPhotosUrls] = useState(jet ? jet.photos.map(item => item.url) : [])
  const [photosFiles, setPhotosFiles] = useState([])

  const isPhotosUploadedRef = useRef(!!jet)
  const photoUploadersRef = useRef([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])

  const onTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const onSeoDescChange = (event) => {
    setSeoDesc(event.target.value)
  }

  const onPriceChange = (event) => {
    setPrice(event.target.value)
  }

  const onMainVideoChange = (event) => {
    setMainVideo(event.target.value)
  }

  const onDescVideoChange = (event) => {
    setDescVideo(event.target.value)
  }

  const onRemovePhoto = (photoIndex) => {
    setPhotosUrls((list) => list.filter((item, index) => photoIndex !== index))
    setPhotosFiles((list) => list.filter((item, index) => photoIndex !== index))
  }

  const onPhotoChange = async (files) => {
    const filesArr = Array.from(files)
    isPhotosUploadedRef.current = false
    setPhotosFiles(list => list.concat(filesArr))
    const urls = await Promise.all(filesArr.map((file) => fileReader(file)))
    setPhotosUrls(list => list.concat(urls))
  }

  const onClickSaveBtn = async () => {
    const payload = {
      title,
      price,
      seoDesc,
      mainVideo,
      descVideo,
      photosUrls,
      photosFiles,
    }
    await onSaveJet(payload)
  }

  return {
    state: {
      isSavingAvailable: title && price,
      isEditMode: !!jet,
      title,
      seoDesc,
      price,
      mainVideo,
      descVideo,
      photosUrls,
      photoUploaders: photoUploadersRef.current,
    },
    handlers: {
      onTitleChange,
      onSeoDescChange,
      onPriceChange,
      onMainVideoChange,
      onDescVideoChange,
      onRemovePhoto,
      onPhotoChange,
      onClickSaveBtn,
    }
  }
}
