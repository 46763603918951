import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { JetPayload } from '@domain/jet/payload'
import { createJet } from '@repositories/jets'
import { useEditorWithImage } from '@application/hooks/editor-with-image'
import { jetsLocation } from '@application/locations'

import { useUploadPhotos } from '../../application/hooks/useUploadPhotos'

export function useCreateJet() {
    const navigate = useNavigate()
    const { initEditor, getEditorData } = useEditorWithImage()
    const { uploadPhotos } = useUploadPhotos()

    const onCreateJet = async (payload) => {
        const uploadedPhotosUrls = await uploadPhotos(payload.photosFiles)
        const editorData = await getEditorData()
        await createJet(new JetPayload({...payload, photosUrls: uploadedPhotosUrls, editorData}).DTO)
        return navigate(jetsLocation)
    }

    useEffect(() => {
        initEditor()
    }, [])

    return {
        handlers: {
            onCreateJet,
        }
    }
}
