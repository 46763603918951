import { useQuery } from 'react-query'
import { getAllVehicles } from '@repositories/vehicles'
import { Vehicle } from '@domain/vehicle'

const KEY = 'all-vehicles'

export function useGetAllVehicles(options) {
  return useQuery(
    KEY,
    async () => {
      const vehiclesResult = await getAllVehicles()

      if (vehiclesResult.isOk()) {
        const list = await vehiclesResult.value.json()
        return list.data.map(item => new Vehicle(item))
      }

      throw new Error('Can not get all vehicles.')
    },
    options,
  )
}
