import { useQuery } from 'react-query'
import { getJet } from '@repositories/jets'
import { Jet } from '@domain/jet'

export function useGetJet(id, options) {
    return useQuery(
        `jet/${id}`,
        async () => {
            const jetResult = await getJet(id)

            if (jetResult.isOk()) {
                const data = await jetResult.value.json()
                return new Jet(data)
            }

            throw new Error(`Can not get jet with id: ${id}.`)
        },
        options,
    )
}
