import { useRef } from 'react'

export const useFileInput = (props) => {
  const fileInputRef = useRef()

  const onFileChange = async () => {
    const files = fileInputRef.current.files
    const file = files[0];
    props.onChange(props.multiple ? files : file)
    fileInputRef.current.value = ''
  }

  const onDropFile = (event) => {
    fileInputRef.current.files = event.dataTransfer.files;
    event.preventDefault();
    return onFileChange()
  }

  return {
    state: {
      fileInputRef
    },
    handlers: {
      onFileChange,
      onDropFile
    }
  }
}
