import { useMemo } from 'react'
import {
  Button,
  Icon,
  MenuItem,
} from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'

import { useVehiclesSelect } from './hook'
import styles from './index.module.scss'

export const VehiclesSelect = ({ value, onChange }) => {
  const { state, handlers } = useVehiclesSelect(value, onChange)

  const renderSelectOption = (vehicle, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <div className={styles.option} onClick={handleClick} key={vehicle.id}>
        <img className={styles.optionImg} src={vehicle.thumbnail} alt="Vehicle photo" />
        <p className={styles.optionName}>{vehicle.name}</p>
        {handlers.isVehicleSelected(vehicle) && (
          <Icon className={styles.optionActive} icon="tick" />
        )}
      </div>
    )
  }

  const renderSelectTag = (vehicle) => {
    return <p className={styles.selectTag} key={vehicle.id}>{vehicle.name}</p>
  }

  const clearButton = useMemo(
    () => value.length ?
      <Button icon="cross" minimal onClick={handlers.onClearVehicles} /> :
      null,
    [value.length]
  );

  return (
    <MultiSelect
      className={styles.select}
      fill
      itemsEqual={handlers.isVehiclesEqual}
      itemPredicate={handlers.isVehicleQueryMatch}
      items={state.vehicles}
      selectedItems={value}
      onItemSelect={handlers.onSelectVehicle}
      itemRenderer={renderSelectOption}
      tagRenderer={renderSelectTag}
      tagInputProps={{
        onRemove: (node) => handlers.onRemoveSelectedVehicle(Number(node.key)),
        rightElement: clearButton
      }}
      popoverProps={{ minimal: true, popoverClassName: styles.selectPopover }}
      noResults={<MenuItem disabled text="No results." />}
    />
  )
}
