import React from 'react'

import Nav from '../_components_/nav'
import { ArticleForm } from '../_features_/article-form'
import { useEditArticle } from './hook'

export const EditArticlePage = () => {
  const { state, handlers } = useEditArticle()
  return (
    <>
      <Nav />
      {state.isEditingAvailable && (
        <ArticleForm onSaveArticle={handlers.onUpdateArticle} article={state.article} />
      )}
    </>
  )
}
