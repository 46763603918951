import { useQuery } from 'react-query'
import { getVehicle } from '@repositories/vehicles'
import { Vehicle } from '@domain/vehicle'

export function useGetVehicle(id, options) {
  return useQuery(
    `vehicle/${id}`,
    async () => {
      const vehicleResult = await getVehicle(id)

      if (vehicleResult.isOk()) {
        const data = await vehicleResult.value.json()
        return new Vehicle(data)
      }

      throw new Error(`Can not get vehicle with id: ${id}.`)
    },
    options,
  )
}
