import { useMemo, useReducer } from 'react'
import { StorageFabric } from '@tools/storage'

import { AppStateContext } from './app-state.context'
import { AppHandlersContext } from './app-handlers.context'
import { useCurrentUser } from '../../hooks/http/current-user'

const storage = StorageFabric.getLocalStorage()

function appStateUnauthenticatedReducer(appState, action,) {
  switch (action.type) {
    case 'auth':
      return {
        state: action.type,
        user: action.payload.user,
      }
    default:
      return appState
  }
}

function appStateAuthenticatedReducer(
  appState,
  action,
) {
  switch (action.type) {
    case 'un-auth':
      return {
        state: action.type,
        user: null
      }
    case 'auth':
      return {
        state: action.type,
        user: action.payload.user,
      }
    default:
      return appState
  }
}

function appStateReducer(appState, appAction) {
  switch (appState.state) {
    case 'un-auth':
      return appStateUnauthenticatedReducer(appState, appAction)
    case 'auth':
      return appStateAuthenticatedReducer(appState, appAction)
    default:
      return appState
  }
}

export function AppStateProvider(props) {
  const [appStates, dispatch] = useReducer(appStateReducer, {
    state: storage.get('user-id') ? 'auth': 'un-auth',
  })

  function setUnauthenticatedState() {
    dispatch({ type: 'un-auth' })
  }

  function setAuthenticatedState(user) {
    dispatch({ type: 'auth', payload: { user } })
  }

  const appHandlers = useMemo(() => {
    return {
      setUnauthenticatedState,
      setAuthenticatedState
    }
  }, [dispatch])

  useCurrentUser({
    onSuccess: (user) => {
      setAuthenticatedState(user)
    },
    onError: () => {
      setUnauthenticatedState()
    },
    refetchOnWindowFocus: false,
    retry: false,
  })

  return (
    <AppStateContext.Provider value={appStates}>
      <AppHandlersContext.Provider value={appHandlers}>
        {props.children}
      </AppHandlersContext.Provider>
    </AppStateContext.Provider>
  )
}
