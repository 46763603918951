import { useMemo } from 'react'
import { VehiclePayload } from '@domain/vehicle/payload'

export const useVehicleOption = () => {
  const fuel = useMemo(() => ['Fuel type', ...VehiclePayload.getFuelOptions()], [])
  const seats = useMemo(() => ['Number of seats', ...VehiclePayload.getSeatsOptions()], [])
  const doors = useMemo(() => ['Number of doors', ...VehiclePayload.getDoorsOptions()], [])

  return {
    state: {
      fuel,
      seats,
      doors
    }
  }
}

