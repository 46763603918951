import { useState } from 'react'

export function useControlPanel() {
  const [isShowDeleteConfirmation, setDeleteConfirmationStatus] = useState(false)

  const onOpenDeleteConfirmation = () => {
    setDeleteConfirmationStatus(true)
  }

  const onCloseDeleteConfirmation = () => {
    setDeleteConfirmationStatus(false)
  }

  return {
    state: {
      isShowDeleteConfirmation,
    },
    handlers: {
      onOpenDeleteConfirmation,
      onCloseDeleteConfirmation,
    }
  }
}
