import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
import logoUrl from '@assets/techiia.png'

import { useLoginPage } from './hook'
import styles from './index.module.scss'

export const LoginPage = () => {
  const { state, handlers } = useLoginPage()

  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={logoUrl} alt="techiia" width={200} height={32} />
          </div>
          <p>Admin panel</p>
        </div>
        <form onSubmit={handlers.handleSubmit}>
          <FormGroup label="Nickname" labelFor="nickaname">
            <InputGroup
              id="nickaname"
              name="nickname"
              placeholder="Enter your nickname"
              className="bp3-large"
              value={state.values.nickname}
              onChange={handlers.handleChange}
              required
            />
          </FormGroup>
          <FormGroup label="Password" labelFor="password">
            <InputGroup
              id="password"
              type="password"
              name="password"
              placeholder="Enter your password"
              className="bp3-large"
              value={state.values.password}
              onChange={handlers.handleChange}
              required
            />
          </FormGroup>
          <Button
            disabled={state.isSubmitting}
            type="submit"
            className="bp3-button bp3-intent-primary bp3-large"
            text="Login"
          />
        </form>
      </div>
    </div>
  )
}
