import { useState } from 'react'
import { useGetVehicles } from '@application/hooks/http/get-vehicles'
import { useGetAllVehicles } from '@application/hooks/http/get-all-vehicles'
import { usePagination } from '@application/hooks/pagination'
import { useSearchTherm } from '@application/hooks/search-therm'

export const useVehiclesList = () => {
  const [selectedBrand, localSelectBrand] = useState('')
  const [selectedModel, localSelectModel] = useState('')
  const [selectedYear, localSelectYear] = useState('')
  const {
    data: paginationData,
    currentPage,
    totalPages,
    setTotalPages,
    setCurrentPage,
    onResetPagination,
  } = usePagination()
  const { searchTerm, debouncedSearchTerm, onSearchTermChange } = useSearchTherm({ onChange: onResetPagination })

  const allVehiclesQuery = useGetAllVehicles({
    refetchOnWindowFocus: false,
    retry: false,
  })

  const vehiclesQuery = useGetVehicles(
    paginationData,
    { brand: selectedBrand, model: selectedModel, year: selectedYear, search: debouncedSearchTerm },
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        setTotalPages(data.pagination.pages)
      }
    }
  )

  const vehicles = vehiclesQuery.data?.vehicles ?? []

  const selectBrand = (brand) => {
    onResetPagination()
    localSelectBrand(brand)
    localSelectModel('')
    localSelectYear('')
  }

  const selectModel = (model) => {
    onResetPagination()
    localSelectModel(model)
  }

  const selectYear = (year) => {
    onResetPagination()
    localSelectYear(year)
  }

  const handleClearAll = () => {
    onResetPagination()
    localSelectBrand('')
    localSelectModel('')
    localSelectYear('')
  }

  return {
    state: {
      allVehicles: allVehiclesQuery.data ?? [],
      vehicles,
      selectedBrand,
      selectedModel,
      selectedYear,
      searchTerm,
      currentVehiclesPage: currentPage,
      totalVehiclesPage: totalPages
    },
    handlers: {
      selectBrand,
      selectModel,
      selectYear,
      onSearchTermChange,
      handleClearAll,
      setCurrentVehiclesPage: setCurrentPage
    }
  }
}
