import { useQuery } from 'react-query'
import { getJets } from '@repositories/jets'
import { Jet } from '@domain/jet'

const KEY = 'jets'

export function useGetJets(pagination, filters, options) {
  return useQuery(
      [KEY, pagination.offset, filters.search],
    async () => {
      const jetsResult = await getJets(pagination, filters)

      if (jetsResult.isOk()) {
        const list = await jetsResult.value.json()
        return {
          jets: list.data.map(item => new Jet(item)),
          pagination: {
            total: list.total,
            pages: list.last_page,
          }
        }
      }

      throw new Error('Can not get jets.')
    },
    options,
  )
}
