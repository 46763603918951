import { useRef } from 'react'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Underline from '@editorjs/underline'

export function useEditor(config) {
  const editorRef = useRef()

  const initEditor = async (data) => {
    const tools = config?.tools || {}
    editorRef.current = new EditorJS({
      holder: 'editor',
      placeholder: 'Start writing car description here...',
      tools: {
        header: Header,
        list: List,
        underline: Underline,
        ...tools,
      }
    })
    await editorRef.current.isReady
    if (data && typeof data === 'string') await renderFromHTML(data)
    else if (data && data.blocks.length) await render(data)
  }

  const getEditorData = async () => {
    return editorRef.current.save()
  }

  const render = async (json) => {
    return await editorRef.current.blocks.render(json)
  }

  const renderFromHTML = async (html) => {
    return await editorRef.current.blocks.renderFromHTML(html)
  }

  return {
    initEditor,
    getEditorData
  }
}
