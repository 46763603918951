import ImageTool from '@editorjs/image'
import { createPhoto } from '@repositories/photos'

import { useEditor } from './editor'

const PHOTO_UPLOAD_BUCKET = 'photos'
const SUCCESS_UPLOAD_STATUS = 1
const FAIL_UPLOAD_STATUS = 0

export const useEditorWithImage = () => {

  return useEditor({
    tools: {
      image: {
        class: ImageTool,
        config: {
          uploader: {
            uploadByFile: async (file) => {
              const result = await createPhoto(file, PHOTO_UPLOAD_BUCKET)
              if (result.isOk()) {
                const data = await result.value.json()
                return {
                  success: SUCCESS_UPLOAD_STATUS,
                  file: { url: data.url[0] }
                }
              } else {
                return {
                  success: FAIL_UPLOAD_STATUS,
                  file: { url: '' }
                }
              }
            },
            uploadByUrl: async (url) => {
              return {
                success: SUCCESS_UPLOAD_STATUS,
                file: { url }
              }
            }
          }
        }
      }
    }
  })

}
