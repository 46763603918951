import { useNavigate } from 'react-router-dom'
import { deleteArticle } from '@repositories/articles'
import { articlesLocation } from '@application/locations'

export function useControlPanel(article) {
  const navigate = useNavigate()

  const onRemoveArticle = async () => {
    if (article?.id) {
      await deleteArticle(article.id)
      navigate(articlesLocation)
    }
  }
  return {
    handlers: {
      onRemoveArticle,
    }
  }
}
