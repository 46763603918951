import { HttpFabric } from '@tools/http'

const http = HttpFabric.getFetchHttp()

export const getJets = async (pagination, filters) => {
  let params = `offset=${pagination?.offset || 0}&limit=${pagination?.limit || 0}`
  if (filters?.search) params += `&search=${filters.search}`
  return http.get(`/api/jetski?${params}`)
}

export const getJet = async (id) => {
  return http.get(`/api/jetski/${id}?editorjs=true`)
}

export const createJet = async (data) => {
  return http.post(`/api/jetski`, data)
}

export const updateJet = async (id, data) => {
  return http.put(`/api/jetski/${id}`, data)
}

export const removeJet = async (id) => {
  return http.remove(`/api/jetski/${id}`)
}
